import React from 'react'
import { Box } from '@mui/material'

const Tile = ({icon, title, content}) => {
  return (
    <Box sx={{ padding: '20px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box 
                sx={{ 
                    width: '40px', 
                    height: '40px', 
                    backgroundColor: '#0060a4',
                    marginRight: '20px',
                    display: 'grid',
                    placeItems: 'center',
                    borderRadius: '5px'
                }}>
                    <img src={icon} alt="icon" width='80%' />
            </Box>
            <Box
                sx={{ 
                    fontSize:'1.5rem',
                    fontWeight: '400',
                    color: '#173a63',
                 }}
            >
                {title}
            </Box>
        </Box>
        <Box
            sx={{ 
                fontSize:'1rem',
                fontWeight: '100',
                color: '#173a63',
                marginTop: '20px',
                textAlign: "justify",
                textJustify: "inter-word",
             }}
        >
            {content}
        </Box>
    </Box>
  )
}

export default Tile