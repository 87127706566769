import React from 'react'
import { Box } from '@mui/material'
import { footerBox } from '../styles/styles';

const logo = '../../images/logos/logo_white.png'
const Footer = () => {

  return (
    <Box style={footerBox}>
      <Box sx={{ marginLeft: '20px', fontSize: '16px', fontWeight: '600' }}>
      <p>
          85Solutions
      </p>
      <p style={{ fontSize: '10px' }}>
          3A, Gbenga Ademilegun Street,<br />
          Parkview Estate, Ikoyi, Lagos, Nigeria.<br />
          +234 808 221 3598 | www.85solutions.com<br />
          &copy; 2024
        </p>
      </Box>
      <Box sx={{ marginRight: '20px' }}>
        <img 
          src={logo}
          alt='white_logo'
          width='80px'
        />
      </Box>
    </Box>
  )
}

export default Footer