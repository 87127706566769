import React from 'react'
import { Box } from '@mui/material'
import MenuBar from '../components/MenuBar'
import MiniSlider from '../components/MiniSlider'
import Title from '../components/Title'
import ResponsiveLayoutLeftPic from '../components/ResponsiveLayoutLeftPic'
import ResponsiveLayoutRightPic from '../components/ResponsiveLayoutRightPic'
import { pageTitleServices, pageDescriptionServices, services } from '../data/database'
import Footer from '../global/Footer'

const Services = () => {
  return (
    <>
    <MiniSlider/>
    <MenuBar/>
    <Box >
      <Title pageTitle={pageTitleServices} pageDescription={pageDescriptionServices}/>
      <Box
        sx={{ 
          width: '90%', 
          margin: 'auto', 
          marginTop: '20px',
          position: 'relative',
        }}>
        <Box
          sx={{ 
            width: '100%', 
            height: '100px',
            display: 'grid',
            placeItems: 'center',
            color: '#173a63',
            fontSize: '35px',
            fontWeight: '200',
           }}
        >
          Services
        </Box>
        <ResponsiveLayoutLeftPic 
          image={services[0].image}
          icon={services[0].icon}
          title={services[0].title}
          content={services[0].content}
        />
        <ResponsiveLayoutRightPic 
          image={services[1].image}
          icon={services[1].icon}
          title={services[1].title}
          content={services[1].content}
        />
        <ResponsiveLayoutLeftPic 
          image={services[2].image}
          icon={services[2].icon}
          title={services[2].title}
          content={services[2].content}
        />
        <ResponsiveLayoutRightPic 
          image={services[3].image}
          icon={services[3].icon}
          title={services[3].title}
          content={services[3].content}
        />
        <ResponsiveLayoutLeftPic 
          image={services[4].image}
          icon={services[4].icon}
          title={services[4].title}
          content={services[4].content}
        />
        <ResponsiveLayoutRightPic 
          image={services[5].image}
          icon={services[5].icon}
          title={services[5].title}
          content={services[5].content}
        />
      </Box>
    </Box>
    <Footer />
    </>
  )
}

export default Services