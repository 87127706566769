import React, { useState, useEffect } from 'react';
import { useMediaQuery, Box, IconButton } from '@mui/material';

const logo = '../../images/logos/logo_full.png'
const slides = [
  {
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(../../images/homepage/advisory.png)',
    tagline: 'Advisory and GRC',
    description: `Unlock your business's full potential with our advisory services, providing tailored solutions for growth. Our Governance, Risk Management, and Compliance services ensure seamless navigation of regulations and elevated operational excellence.`,
  },
  {
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(../../images/homepage/it_support.png)',
    tagline: 'IT Support',
    description: 'Empower your business with our IT support services, ensuring seamless operations and maximum uptime for your systems. From troubleshooting to proactive maintenance, our expert team keeps your technology running smoothly, so you can focus on achieving your goals.',
  },
  {
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(../../images/homepage/softwaredevelopment.png)',
    tagline: 'Customized Software Development',
    description: 'Transform your business vision into reality with our tailored Customized Software Development solutions, meticulously crafted to address your unique needs and propel your organization towards unparalleled efficiency and innovation.',
  },
  {
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(../../images/homepage/securitycontrol.png)',
    tagline: 'Security Controls Installation and Optimization',
    description: `Elevate your organization's security posture with our comprehensive Security Controls Installation and Optimization services, ensuring robust protection against cyber threats while optimizing performance for seamless operations and peace of mind.`,
  },
  {
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(../../images/homepage/procurement.png)',
    tagline: 'IT Procurement',
    description: `Simplify and streamline your technology acquisitions with our IT Procurement services, providing expert guidance and efficient processes to acquire the right hardware and software solutions tailored to your business needs, budget, and goals.`,
  },
  {
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(../../images/homepage/securityops.png)',
    tagline: 'Security Operations',
    description: `Enhance your organization's security posture and response capabilities with our Security Operations services, offering proactive threat monitoring, incident detection, and rapid response strategies to safeguard your digital assets and maintain resilience against evolving cyber threats.`,
  },
];


const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab = useMediaQuery('(max-width:770px)')
  
  const back = '../../images/icons/back.png'
  const forward = '../../images/icons/forward.png'

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 10000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <Box sx={{ position: 'relative', width: '100%', aspectRatio: isMobile ? '1 / 1' : '3 / 1', overflow: 'hidden' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transition: 'background-image 0.5s ease-in-out',
          backgroundImage: slides[currentSlide].background,
        }}
      >
        <img src={logo} alt="logo" style={{ height: isTab ? '40px' : '80px', margin: '20px', marginLeft: '30px'}}/>
        <Box sx={{ position: 'absolute', bottom: '50px', left: '30px', marginRight: '30px' }}>
          <Box sx={{ fontSize: '40px', fontWeight: '300', color: '#37a6de' }}>{slides[currentSlide].tagline}</Box>
          <Box sx={{ fontSize: '15px', fontWeight: '100', color: 'white' }}>{slides[currentSlide].description}</Box>
        </Box>
        <Box sx={{ position: 'absolute', top: isTab ? '90%' : '50%', transform: 'translateY(-50%)', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <IconButton onClick={prevSlide} sx={{ cursor: 'pointer' }}>
            <img src={back} alt="back" width='50px'/>
          </IconButton>
          <IconButton onClick={nextSlide} sx={{ cursor: 'pointer' }}>
          <img src={forward} alt="forward" width='50px'/>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Slider;
