import React from 'react'
import { Box } from '@mui/material'
import MenuBar from '../components/MenuBar'
import MiniSlider from '../components/MiniSlider'
import Title from '../components/Title'
import ResponsiveLayoutSquare from '../components/ResponsiveLayoutSquare'
import { pageTitleAbout, pageDescriptionAbout, textAbout, packAbout, coreValues } from '../data/database'
import Footer from '../global/Footer'

const About = () => {
  return (
    <>
    <MiniSlider/>
    <MenuBar/>
    <Box >
      <Title pageTitle={pageTitleAbout} pageDescription={pageDescriptionAbout}/>
      <Box
        sx={{ 
          width: '90%', 
          margin: 'auto', 
          marginTop: '20px',
          position: 'relative',
        }}>
        <Box
          sx={{ 
            width: '100%', 
            height: '100px',
            display: 'grid',
            placeItems: 'center',
            color: '#173a63',
            fontSize: '35px',
            fontWeight: '200',
           }}
        >
          Who we are.
        </Box>
        <Box sx={{ width: '100%', color: '#173a63', fontSize: '1rem', marginBottom: '20px', fontWeight: '100', textAlign: 'justify' }}>
          {textAbout.map((line, index) =>(
            <Box
              key={index}
              sx={{ 
                width: '100%',
                color: '#173a63', 
                fontSize: '1rem', 
                marginBottom: '20px', 
                fontWeight: '100', 
                textAlign: 'justify' 
              }}
            >
              {line}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
    <Box sx={{ width: '90%', margin: 'auto' }}>
      <ResponsiveLayoutSquare pack={packAbout}/>
    </Box>
    <Box
          sx={{ 
            width: '100%', 
            height: '100px',
            display: 'grid',
            placeItems: 'center',
            color: '#173a63',
            fontSize: '35px',
            fontWeight: '200',
            marginTop: '30px',
           }}
        >
          Core Values.
        </Box>
    <Box sx={{ width: '90%', margin: 'auto' }}>
      <ResponsiveLayoutSquare pack={coreValues}/>
    </Box>
    <Footer />
    </>
  )
}

export default About