const headerBox = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  height: "100px",
};

const bodyBox = {
  width: "auto",
  height: "auto",
  background: "rgba(128, 128, 128, 0.5)",
  backdropFilter: "blur(5px)",
  overflowY: "auto",
};

const footerBox = {
  display: "flex",
  width: "auto",
  height: "150px",
  background: "#173a63",
  justifyContent: "space-between",
  alignItems: "center",
  color: "white",
};

const menuBox = {
  flex: 1,
  height: "50px",
  color: "black",
  textDecoration: "none",
  backgroundColor: "#37a6de59",
  borderRadius: "0 0 5px 5px",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "rgba(128, 128, 128, 0.5)",
  },
};

const activeMenuBox = {
  backgroundColor: "#37a6de",
};

const buttonBox = {
  display: "grid",
  placeItems: "center",
  height: "100%",
};

const advertBox = {
  flex: 1,
  width: "100%",
  aspectRatio: "3/1",
  position: "relative",
  color: "white",
};

const taglineCss = {
  position: "absolute",
  bottom: 0,
  left: 0,
  padding: "8px",
  color: "white",
  fontSize: 30,
  fontWeight: "100",
  margin: "10px",
};

const descriptionCss = {
  margin: "20px",
  textAlign: "justify",
  textJustify: "inter-word",
  fontWeight: "100",
};

const socialMediaBox = {
  display: "flex",
  margin: "50px",
  justifyContent: "space-around",
  alignItems: "center",
};

const submitButton = {
  width: "50%",
  height: "50px",
  margin: "auto",
  marginTop: "35px",
  backgroundColor: "#0060a4",
  "&:hover": {
    backgroundColor: "#37a6de",
  },
};

const itemBox = {
  display: "flex",
  flexDirection: "row",
  width: "90%",
  maxHeight: "200px",
  justifyContent: "space-between",
  padding: "5px",
  border: "1px solid black",
  borderRadius: "5px",
  margin: "auto",
  marginBottom: "10px",
};

const counterBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "120px",
  justifyContent: "space-around",
  fontSize: "20px",
  marginRight: "10px",
};

const descriptionBox = {
  flex: 1,
  paddingLeft: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const nameStyle = {
  fontSize: "18px",
  fontWeight: "bold",
};

const descriptionStyle = {
  marginTop: "5px",
  fontSize: "12px",
};

const priceStyle = {
  fontSize: "10px",
  color: "green",
};

export {
  headerBox,
  bodyBox,
  footerBox,
  menuBox,
  activeMenuBox,
  buttonBox,
  advertBox,
  taglineCss,
  descriptionCss,
  socialMediaBox,
  submitButton,
  itemBox,
  counterBox,
  descriptionBox,
  nameStyle,
  descriptionStyle,
  priceStyle,
};
