import React, { useState, useEffect } from 'react';
import { useMediaQuery, Box } from '@mui/material';
import { slides } from '../data/database';

const logo = '../../images/logos/logo.png'

const MiniSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab = useMediaQuery('(max-width:770px)')

  // Function to generate a random index within the slides array length
  const getRandomIndex = () => {
    return Math.floor(Math.random() * slides.length);
  };

  // Function to go to the next random slide
  const nextRandomSlide = () => {
    const randomIndex = getRandomIndex();
    setCurrentSlide(randomIndex);
  };

  // Automatically switch slides every 10 seconds
  useEffect(() => {
    const interval = setInterval(nextRandomSlide, 5000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '120px', overflow: 'hidden' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transition: 'background-image 0.5s ease-in-out',
          backgroundImage: slides[currentSlide],
        }}
      >
        <img src={logo} alt="logo" style={{ height: isTab ? '60px' : '80px', margin: '20px', marginLeft: '30px'}}/>
      </Box>
    </Box>
  );
};

export default MiniSlider;
