import React, {useState} from 'react'
import axios from 'axios'
import { Box, TextField, Button, Typography } from '@mui/material'
import { submitButton } from '../styles/styles'

const MessageBar = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState('');

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    // Validate form fields
    if (!name || !email || !message) {
      setError('Please fill in all fields.');
      return;
    }
    if (!validateEmail(email)) {
      setError('Invalid email format.');
      return;
    }
    // Send data to backend
    sendDataToBackend({ name, email, message });
    // Clear form fields and error message
    setSuccessMessage('Message sent successfully');
    setError('');
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  // Email validation function
  const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  const sendDataToBackend = (data) => {
    console.log(data)
    // axios.post('/api/submit', data)
    // .then((response) => {
    //   console.log('Success:', response.data);
    // })
    // .catch((error) => {
    //   console.error('Error:', error);
    // });
  };

  return (
    <Box >
        <Box
          sx={{ 
            width: '100%', 
            height: '100px',
            display: 'grid',
            placeItems: 'center',
            color: '#173a63',
            fontSize: '25px',
            fontWeight: '200',
           }}
        >
          Drop a message, we'd like to hear from you.
        </Box>
        <TextField
          label="Name"
          variant="standard"
          fullWidth
          margin="dense"
          onChange={handleChangeName}
        />
        <TextField
          label="Email"
          variant="standard"
          fullWidth
          margin="dense"
          onChange={handleChangeEmail}
          type='email'
        />
        <TextField
          label="Message"
          variant="standard"
          fullWidth
          margin="dense"
          onChange={handleChangeMessage}
          multiline
          minRows={3}
          maxRows={5}
        />
        {error && <Typography variant="body2" color="error">{error}</Typography>}
        {successMessage && <Typography variant="body2" color="#37a6de">{successMessage}</Typography>}
        <Box sx={{ width: '100%', display: 'grid', placeItems: 'center', marginBottom: '50px' }}>
          <Button 
            sx={submitButton} 
            variant="contained" 
            onClick={handleSubmit} 
            fullWidth 
          >
            Submit
          </Button>
        </Box>
    </Box>
  )
}

export default MessageBar