import React from 'react'
import { useMediaQuery, Box, IconButton } from '@mui/material'
import { socialMediaBox } from '../styles/styles'

const whatsappIcon = '../../images/icons/whatsapp.png'
const xIcon = '../../images/icons/twitter.png'
const instagramIcon = '../../images/icons/instagram.png'
const tiktokIcon = '../../images/icons/tiktok.png'
const facebookIcon = '../../images/icons/facebook.png'
const youtubeIcon = '../../images/icons/youtube.png'


const SocialMediaBar = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab = useMediaQuery('(max-width:770px)')

  const socialMediaIcons = [
      { label: 'WhatsApp', icon: whatsappIcon, link: 'https://www.whatsapp.com/' },
      { label: 'X', icon: xIcon, link: 'https://www.twitter.com/' },
      { label: 'Instagram', icon: instagramIcon, link: 'https://www.instagram.com/' },
      { label: 'TikTok', icon: tiktokIcon, link: 'https://www.tiktok.com/' },
      { label: 'Facebook', icon: facebookIcon, link: 'https://www.facebook.com/' },
      { label: 'YouTube', icon: youtubeIcon, link: 'https://www.youtube.com/' },
    ];

  return (
    <Box sx={socialMediaBox}>
        {socialMediaIcons.map((item, index) => (
        <IconButton key={index}>
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <img 
                src={item.icon}
                alt={item.label}
                style={{ 
                  width: isMobile ? '20px' : '30px'
                 }}
            />
          </a>
        </IconButton>
      ))}
    </Box>
  )
}

export default SocialMediaBar