import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery, Box, IconButton } from '@mui/material';
import { menuBox, activeMenuBox, buttonBox } from '../styles/styles';

const menu = '../../images/icons/menu.png'
const logo = '../../images/logos/logo.png'

const MenuBar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab = useMediaQuery('(max-width:770px)')

  const menuItems = [
    { path: '/home', label: 'Home' },
    { path: '/about', label: 'About Us' },
    { path: '/services', label: 'Services' },
    { path: '/portfolio', label: 'Portfolio' },
    { path: '/contact', label: 'Contact Us' },
  ];

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Box sx={{ width: '90%', margin: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          marginTop: '-20px',
          width: '100%',
          height: '100px',
          backgroundColor: '#37a6de',
          alignItems:'center',
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ marginLeft: '20px' }}>
          <IconButton onClick={handleMenuToggle}>
            <img 
              alt= "menu"
              width="20px"
              src={menu}
            />
          </IconButton>
        </Box>
        <Box 
          sx={{ 
            width: '50%', 
            height: '100%', 
            overflow: 'hidden', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center' 
            }}>
          <img 
            src={logo} 
            alt="logo" 
            width='100%'
            style={{ objectFit: 'cover' }}/>
        </Box>
      </Box>
      <Box display="flex" width="100%">
      {isMenuOpen && (
        <Box display="flex" width="100%">
          {menuItems.map((item, index) => (
            <Box
              key={index}
              component={Link}
              to={item.path}
              className="menu-link"
              style={{
                ...menuBox,
                ...(location.pathname === item.path && activeMenuBox),
              }}
            >
              <Box style={buttonBox} sx={{ fontSize: isTab ? '12px' : '1rem' }}>{item.label}</Box>
            </Box>
          ))}
        </Box>
      )}
      </Box>
    </Box>
  );
};

export default MenuBar;
