const aboutImage = "../../images/about.png";
const serviceImage = "../../images/service.png";

const aboutTitle = "About Us";
const serviceTitle = "Our Services";
const portfolioTitle = "The Portfolio";
const contactTitle = "Contact Us";

const aboutTagline = "All you need to know...";
const serviceTagline = "We don't just get the job done, we create experiences.";
const portfolioTagline = "Our distinguished Clientele.";
const contactTagline = "Let us get talking";

const aboutPack = [
  {
    icon: "../../images/icons/vision.png",
    title: "Vision",
    content:
      "To be the preferred tech-powered solutions provider every business, regardless of industry or scale, wants to partner with when faced with problems they have no idea how to solve.",
  },
  {
    icon: "../../images/icons/mission.png",
    title: "Mission",
    content:
      "To be a trusted partner to our clients, leveraging our expertise in technology to help them navigate complex challenges and achieve success. Our goal is to continuously innovate and evolve our solutions to stay ahead of the curve, remain at the forefront of the industry and be recognized for our innovation, excellence, and commitment to making a positive impact on the world.",
  },
  {
    icon: "../../images/icons/core.png",
    title: "Core Values",
    content:
      "Innovation, Teamwork, Quality, Continuous Learning, Transparency, Courage, Customer-Centricity",
  },
];

const servicePack = [
  {
    icon: "../../images/icons/advice.png",
    title: "Advisory and GRC",
    content: `Unlock your business's full potential with our comprehensive advisory services, tailored to guide you through every strategic decision. Stay ahead of regulations and risks with our GRC solutions, ensuring seamless compliance and optimal governance.`,
  },
  {
    icon: "../../images/icons/it_support.png",
    title: "IT Support",
    content:
      "Elevate your operations with our expert IT support services, providing seamless solutions and proactive assistance to keep your systems running smoothly around the clock.",
  },
  {
    icon: "../../images/icons/softdev.png",
    title: "Customized Software Development",
    content:
      "Empower your business with bespoke software solutions tailored to your exact requirements, ensuring seamless operations and driving productivity to new heights.",
  },
  {
    icon: "../../images/icons/installation.png",
    title: "Security Controls Installation and Optimization",
    content:
      "Fortify your digital fortress with expert security control installation and optimization, safeguarding your valuable assets from potential threats with precision and efficiency.",
  },
  {
    icon: "../../images/icons/procurement.png",
    title: "IT Procurement",
    content:
      "Streamline your IT procurement process effortlessly, ensuring timely acquisition of cutting-edge technologies and solutions tailored to your business needs for optimal performance and growth.",
  },
  {
    icon: "../../images/icons/security.png",
    title: "Security Operations",
    content:
      "Enhance your security operations with our comprehensive solutions, providing advanced monitoring, threat detection, and rapid response capabilities to safeguard your business from evolving cyber threats.",
  },
];

const logos = [
  "../../images/logos/access.png",
  "../../images/logos/at.png",
  "../../images/logos/gt.png",
  "../../images/logos/ihs.png",
  "../../images/logos/lagos.png",
  "../../images/logos/schlum.png",
  "../../images/logos/shell.png",
  "../../images/logos/stanbic.png",
  "../../images/logos/wema.png",
];

const pageTitleAbout = "About Us";
const pageDescriptionAbout = "All you need to know...";

const textAbout1 = `At 85 Solutions, we like to call ourselves The Fixers…
Dramatic? Maybe a little, but is it apt? Certainly.`;

const textAbout2 = `Businesses sometimes encounter problems which require more than the usual capabilities present in most company teams… and that’s where we – “The Fixers” – come in; a highly-skilled team of solution-seekers that swoop in to help properly understand business problems with the ultimate goal of solving them.`;

const textAbout3 = `We have a keen eye for innovation, a heightened knowledge of technology and a sharp sense of commitment to delivering value, both for ourselves and for our clients.`;

const textAbout4 = `As a business, we have amassed years of experience while making sure to stay up-to-date with the latest trends and advancements in technology in order to deliver practical, efficient and cost-effective solutions to the ones who put their trust in us to solve their problems.`;

const textAbout = [textAbout1, textAbout2, textAbout3, textAbout4];

const packAbout = [
  {
    icon: "../../images/icons/vision.png",
    title: "Vision",
    content:
      "To be the preferred tech-powered solutions provider every business, regardless of industry or scale, wants to partner with when faced with problems they have no idea how to solve.",
  },
  {
    icon: "../../images/icons/mission.png",
    title: "Mission",
    content:
      "To be a trusted partner to our clients, leveraging our expertise in technology to help them navigate complex challenges and achieve success. Our goal is to continuously innovate and evolve our solutions to stay ahead of the curve, remain at the forefront of the industry and be recognized for our innovation, excellence, and commitment to making a positive impact on the world.",
  },
];

const coreValues = [
  {
    icon: "../../images/icons/core.png",
    title: "Innovation",
    content:
      "Complex problems require innovative solutions, so we embrace and stay on top of changing trends and technologies in order to drive innovation to our client’s advantage by solving their problems and continually delivering value.",
  },
  {
    icon: "../../images/icons/core.png",
    title: "Teamwork",
    content: `We believe in collaboration, cooperation,
  and mutual support among our employees, and encourage the same approach when working with client teams, to achieve common goals and drive business success.`,
  },
  {
    icon: "../../images/icons/core.png",
    title: "Quality",
    content:
      "We are nothing if not excellent. We do more than just ticking boxes. We commit daily to delivering products and services that beat even our own expectations, through a focus on competence, continuous improvement, and customer satisfaction.",
  },
  {
    icon: "../../images/icons/core.png",
    title: "Continuous Learning",
    content:
      "We are only as good as the minds of our employees, so we are dedicated to providing opportunities for employees to acquire new knowledge, skills, and expertise to improve their performance, proffer brilliant solutions to client’s problems and contribute to our collective growth and development.",
  },
  {
    icon: "../../images/icons/core.png",
    title: "Transparency",
    content:
      "Cultivating trust is important to us. Openness, honesty, clarity and timeliness must accompany all we do, especially in communications with stakeholders about our operations, decisions, and performance.",
  },
  {
    icon: "../../images/icons/core.png",
    title: "Courage",
    content:
      "The bigger the challenge, the bolder we get. We promote boldness, resilience, and ethical behaviour among our employees to take risks, make difficult decisions, and speak up for what is right, even in the face of adversity.",
  },
  {
    icon: "../../images/icons/core.png",
    title: "Customer-Centricity",
    content:
      "For us, it is all about the client. We put their interests first by embodying all our other values, and ensuring that we give each client a listening ear, the best of our abilities and the best possible customer experience this side of the Atlantic.",
  },
];

const color = {
  light: "#37a6de",
  mid: "#0060a4",
  dark: "173a63",
};

const pageTitleContact = "Contact Us";
const pageDescriptionContact = `Reach out, let's talk more`;

const textContact1 = `Whether you would like some more information about our tech solutions, want to request a consultation with our expert fixers or would rather go right ahead and tell us about our next great challenge, we’d be glad to hear from you.`;

const textContact = [textContact1];

const contactDetails = [
  "+234 808 221 3598",
  "3A, Gbenga Ademilegun Street, Parkview Estate, Ikoyi, Lagos, Nigeria.",
  "www.85solutions.com",
];

const pageTitleServices = "Services";
const pageDescriptionServices = "What Services we offer.";

const services = [
  {
    image: "../../images/homepage/advisory.png",
    icon: "../../images/icons/advice.png",
    title: "Advisory and GRC",
    content:
      "We offer advisory and governance, risk, and compliance (GRC) services, which include payment process validation for PCIDSS, PCICP, and PCISLC, ISO certifications such as ISO27001, ISO22301, ISO20000, ISO9001, and ISO27701, NDPR for data privacy, SOC2 for financial and other service organizations, and virtual Chief Information Security Officer (vCISO) services.",
  },
  {
    image: "../../images/homepage/it_support.png",
    icon: "../../images/icons/it_support.png",
    title: "IT Support",
    content: `We provide a range of IT support services to help businesses and individuals manage their technology systems effectively. Our experienced technicians are available 24/7 to provide remote and onsite support, ensuring that any issues are resolved quickly and efficiently.
  `,
  },
  {
    image: "../../images/homepage/softwaredevelopment.png",
    icon: "../../images/icons/softdev.png",
    title: "Customized Software Development",
    content: `Our team of expert developers can design and build custom hardware solutions to meet the specific needs of our clients. We use the latest technologies and best practices to ensure that our oftware solutions are reliable, efficient, and easy to use.`,
  },
  {
    image: "../../images/homepage/securitycontrol.png",
    icon: "../../images/icons/installation.png",
    title: "Security Controls Installation and Optimization",
    content: `Get security controls installation and optimization services, including endpoint detection and response solution and services, activity logging (SIEM) solution and services, fraud management solutions and services, web and network protection solutions and services such as network firewalls and web application firewalls, and more.`,
  },
  {
    image: "../../images/homepage/procurement.png",
    icon: "../../images/icons/procurement.png",
    title: "IT Procurement",
    content:
      "We provide our customers with a one-stop-shop for all IT needs including hardware and related services in order to meet our clients’ needs and their operational requirements while also optimising and reducing risks associated with procurement. Also, we offer the best prices in the market for our customer’s hardware, enabling a cost-effective solution for them.",
  },
  {
    image: "../../images/homepage/securityops.png",
    icon: "../../images/icons/security.png",
    title: "Security Operations",
    content:
      "Our company provides security operations services, including 24/7 security monitoring, alerting, and incident response, 24/7 digital forensics and incident response services, threat risk assessments, vulnerability assessment and penetration testing services, application source code review, and blockchain security testing services.",
  },
];

const slides = [
  "url(../../images/tech/1.png)",
  "url(../../images/tech/2.png)",
  "url(../../images/tech/3.png)",
  "url(../../images/tech/4.png)",
  "url(../../images/tech/5.png)",
  "url(../../images/tech/6.png)",
  "url(../../images/tech/7.png)",
  "url(../../images/tech/8.png)",
  "url(../../images/tech/9.png)",
  "url(../../images/tech/10.png)",
  "url(../../images/tech/11.png)",
  "url(../../images/tech/12.png)",
];

export {
  aboutImage,
  serviceImage,
  aboutTitle,
  serviceTitle,
  portfolioTitle,
  contactTitle,
  aboutTagline,
  serviceTagline,
  portfolioTagline,
  contactTagline,
  aboutPack,
  servicePack,
  logos,
  pageTitleAbout,
  pageDescriptionAbout,
  textAbout,
  packAbout,
  coreValues,
  color,
  pageTitleContact,
  pageDescriptionContact,
  textContact,
  contactDetails,
  pageTitleServices,
  pageDescriptionServices,
  services,
  slides,
};
