import React from 'react'
import { Box } from '@mui/material'
import MenuBar from '../components/MenuBar'
import MiniSlider from '../components/MiniSlider'
import Title from '../components/Title'
import Footer from '../global/Footer'

const pageTitle = 'Portfolio'
const pageDescription = 'Our Clients and the Projects We Have Worked On.'

const Portfolio = () => {
  return (
    <>
    <MiniSlider/>
    <MenuBar/>
    <Box >
      <Title pageTitle={pageTitle} pageDescription={pageDescription}/>
      <Box
        sx={{ 
          width: '90%', 
          margin: 'auto', 
          marginTop: '20px',
          position: 'relative',
        }}>
        <Box
          sx={{ 
            width: '100%', 
            height: '100px',
            display: 'grid',
            placeItems: 'center',
            color: '#173a63',
            fontSize: '35px',
            fontWeight: '200',
           }}
        >
          See our clients and projects below.
        </Box>
      </Box>
    </Box>
    <Footer />
    </>
  )
}

export default Portfolio