import React from 'react'
import { Box } from '@mui/material'

const Title = ({pageTitle, pageDescription}) => {
  return (
    <Box 
        sx={{ 
          width: '90%', 
          height: '150px', 
          backgroundColor: '#0060a4',
          margin: 'auto', 
          marginTop: '20px',
          position: 'relative'
        }}>
        <Box sx={{ position: 'absolute', left: '20px', bottom: '20px' }}>
          <Box sx={{ fontSize: '40px', fontWeight: '300', color: '#37a6de' }}>{pageTitle}</Box>
          <Box sx={{ fontSize: '15px', fontWeight: '100', color: 'white' }}>{pageDescription}</Box>
        </Box>
      </Box>
  )
}

export default Title