import React from 'react'
import { Box } from '@mui/material'

const HomePageTitle = ({title, tagline}) => {
  return (
    <Box 
      sx={{ 
        width: '90%', 
        height: '150px', 
        backgroundColor: '#0060a4',
        margin: 'auto', 
        marginTop: '20px',
        display: 'grid',
        placeItems: 'center'
      }}>
      <Box sx={{ color: 'white', fontWeight: '100', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <Box sx={{ fontSize: '40px', fontWeight: '300', color: '#37a6de', marginBottom: '10px' }}>{title}</Box>
        <Box sx={{ fontSize: '15px', fontWeight: '100', color: 'white' }}>{tagline}</Box>
      </Box>
    </Box>
  )
}

export default HomePageTitle