import React from 'react';
import Tile from './Tile';
import { Box } from '@mui/material';

const ResponsiveLayoutRightPic = ({image, icon, title, content}) => {
  return (
    <>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        gap: 2,
        width: '90%',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: '50px',
      }}
    >
      <Box
        sx={{
            display: 'grid',
            gap: 2,
            width: '100%',
            gridTemplateColumns: { xs: '1fr', md: '1fr' },
            placeItems: 'center',
          }}
      >
      <Tile icon={icon} title={title} content={content} />
      </Box>
      <Box>
        <img src={image} alt="image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Box>
    </Box>
    </>
  );
};

export default ResponsiveLayoutRightPic;
