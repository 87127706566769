import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom' 

const logo = "../../images/logos/logo.png"

const Intro = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab = useMediaQuery('(max-width:770px)')

  return (
    <Box sx={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
        <Box
           component={Link}
           to='/home'
        >
            <img 
                src={logo}
                alt='logo'
                style={{ 
                  width: isMobile ? '100px' : '150px'
                 }}
            />
        </Box>
    </Box>
  )
}

export default Intro