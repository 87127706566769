import React, { useRef, useEffect } from 'react';
import { useMediaQuery, Box } from '@mui/material';

const LogoSlider = ({logos}) => {
    const containerRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTab = useMediaQuery('(max-width:770px)')
    let scrollInterval;

    useEffect(() => {
        const container = containerRef.current;
    
        const startScrolling = () => {
          scrollInterval = setInterval(() => {
            container.scrollLeft += 1;
            if (container.scrollLeft >= container.scrollWidth / 2) {
              container.scrollLeft = 0;
            }
          }, 20);
        };
    
        const stopScrolling = () => {
          clearInterval(scrollInterval);
        };
    
        container.addEventListener('mouseenter', stopScrolling);
        container.addEventListener('mouseleave', startScrolling);
    
        startScrolling();
    
        return () => {
          container.removeEventListener('mouseenter', stopScrolling);
          container.removeEventListener('mouseleave', startScrolling);
          stopScrolling();
        };
      }, [logos]);

  return (
    <Box sx={{ width: 'auto' }}>
        <Box
        sx={{
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            width: '90%',
            margin: 'auto',
        }}
        ref={containerRef}
        >
        {logos.map((logo, index) => (
          <img
            key={index}
            src={logo}
            alt={`Logo ${index}`}
            style={{ width: isTab ? '80px' : '200px', margin: '20px', borderRadius: '25%' }}
          />
        ))}
        {logos.map((logo, index) => (
          <img
            key={`clone-${index}`}
            src={logo}
            alt={`Logo Clone ${index}`}
            style={{ width: isTab ? '80px' : '200px', margin: '20px', borderRadius: '25%' }}
          />
        ))}
        </Box>
    </Box>
  );
};

export default LogoSlider;
