import React from 'react'
import { Box } from '@mui/material'
import MenuBar from '../components/MenuBar'
import MiniSlider from '../components/MiniSlider'
import Title from '../components/Title'
import MessageBar from '../components/MessageBar'
import ContactUs from '../components/ContactUs'
import SocialMediaBar from '../components/SocialMediaBar'
import { pageTitleContact, pageDescriptionContact, textContact, contactDetails } from '../data/database'
import Footer from '../global/Footer'

const Contact = () => {
  return (
    <>
    <MiniSlider/>
    <MenuBar/>
    <Box >
      <Title pageTitle={pageTitleContact} pageDescription={pageDescriptionContact}/>
      <Box
        sx={{ 
          width: '90%', 
          margin: 'auto', 
          marginTop: '20px',
          position: 'relative',
        }}>
        <Box
          sx={{ 
            width: '100%', 
            height: '100px',
            display: 'grid',
            placeItems: 'center',
            color: '#173a63',
            fontSize: '35px',
            fontWeight: '200',
           }}
        >
          Get in touch
        </Box>
        <Box sx={{ width: '100%', color: '#173a63', fontSize: '1rem', marginBottom: '20px', fontWeight: '100', textAlign: 'justify', display: 'grid', placeItems: 'center'}}>
          {textContact.map((line, index) =>(
            <Box
              key={index}
              sx={{ 
                width: '100%',
                maxWidth: '800px', 
                color: '#173a63', 
                fontSize: '1rem', 
                marginBottom: '20px', 
                fontWeight: '100', 
                textAlign: 'justify' 
              }}
            >
              {line}
            </Box>
          ))}
        </Box>
      </Box>
      <Box m='auto' width="80%" maxWidth='800px'>
        <MessageBar />
      </Box>
      <ContactUs contactDetails={contactDetails}/>
      <Box m='auto' width="90%">
        <SocialMediaBar />
      </Box>
    </Box>
    <Footer />
    </>
  )
}

export default Contact