import React from 'react';
import Tile from './Tile';
import { Box } from '@mui/material';

const ResponsiveLayoutSquare = ({pack}) => {
  return (
    <Box
    sx={{
        display: 'grid',
        gap: 2,
        width: '100%',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        }}
    >
    {pack.map((item, index) => (
        <Tile key={index} icon={item.icon} title={item.title} content={item.content} />
    ))}
    </Box>
  );
};

export default ResponsiveLayoutSquare;
