import React from 'react';
import Tile from './Tile';
import { Box } from '@mui/material';
import HomePageTitle from './HomePageTitle';

const ResponsiveLayoutAlt = ({image, title, tagline, pack}) => {
  return (
    <>
      <HomePageTitle title={title} tagline={tagline} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1.5fr 1fr' },
          gap: 2,
          width: '90%',
          margin: 'auto',
          marginTop: '20px',
        }}
      >
        <Box sx={{ order: { xs: 1, md: 2 } }}>
          <img src={image} alt="image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
        <Box
          sx={{
              display: 'grid',
              gap: 2,
              width: '100%',
              gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
            }}
        >
          {pack.map((item, index) => (
            <Tile key={index} icon={item.icon} title={item.title} content={item.content} />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ResponsiveLayoutAlt;
