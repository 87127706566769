import React from 'react'
import { Box } from '@mui/material'
import Slider from '../components/Slider'
import MenuBar from '../components/MenuBar'
import SocialMediaBar from  '../components/SocialMediaBar'
import ResponsiveLayout from '../components/ResponsiveLayout'
import ResponsiveLayoutAlt from '../components/ResponsiveLayoutAlt'
import HomePageTitle from '../components/HomePageTitle'
import LogoSlider from '../components/LogoSlider'
import ContactUs from '../components/ContactUs'
import { aboutImage, serviceImage, aboutTitle, serviceTitle, portfolioTitle, contactTitle, aboutTagline, serviceTagline, portfolioTagline, contactTagline, aboutPack,servicePack, logos, contactDetails } from '../data/database'
import Footer from '../global/Footer'

const Home = () => {
  return (
    <>
      <Slider/>
      <MenuBar/>
      <ResponsiveLayout image={aboutImage} title={aboutTitle} tagline={aboutTagline} pack={aboutPack}/>
      <ResponsiveLayoutAlt image={serviceImage} title={serviceTitle} tagline={serviceTagline} pack={servicePack}/>
      <Box sx={{ width: '100vw' }}>
        <HomePageTitle title={portfolioTitle} tagline={portfolioTagline}/>
        <LogoSlider logos={logos}/>
      </Box>
      <Box>
        <HomePageTitle title={contactTitle} tagline={contactTagline}/>
        <ContactUs contactDetails={contactDetails}/>
        <SocialMediaBar/>
      </Box>
      <Footer />
    </>
  )
}

export default Home