import React from 'react';
import { Box } from '@mui/material';

const ContactUs = ({contactDetails}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
        gap: 2,
        width: '90%',
        margin: 'auto',
        marginTop: '50px',
        marginBottom: '50px',
      }}
    >
      {contactDetails.map((detail, index) => (
        <Box
          key={index}
          sx={{ 
            display: 'grid',
            placeItems: 'center',
            textAlign:  'center',
            color: '#173a63',
            marginBottom: '20px',
          }}
        >
          {detail}
        </Box>
      ))}
    </Box>
  );
};

export default ContactUs;
